body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1 {
    text-align: center;
}

label,
select,
button {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
}

button {
    cursor: pointer;
}