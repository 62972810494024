/* General styles */
.app-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
}

.h1 {
    text-align: center;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.state-label {
    font-weight: bold;
}

.dropdown {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin: 0 auto;
}

.outlet-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.button {
    background-color: #ffc800;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.back-button {
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background-color: #6c757d;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #5a6268;
}

/* Sub-header styles */
.sub-header {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .app-container {
        padding: 10px;
    }

    .dropdown {
        font-size: 14px;
        max-width: 100%;
        padding: 8px;
        margin: 0 10px;
    }

    .button {
        font-size: 12px;
        padding: 8px;
        width: 100%;
        margin: 5px 0;
    }

    .sub-header {
        font-size: 16px;
    }

    .outlet-grid {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 5px;
    }

    .back-button {
        font-size: 12px;
        padding: 8px;
        width: 100%;
        margin: 5px 0;
    }
}